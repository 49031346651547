<template>
  <div class="applyshop">
    <!-- 主体 -->
    <div class="mainbody">
      <div class="mainbody-title">
        <span>小规模纳税人登记申请</span> -
        <span>{{ $route.query.regionName }}</span>
      </div>

      <div class="personal-info">
        <div class="info-name">姓名:&nbsp;&nbsp;{{ legalName }}</div>
        <div class="info">
          <div class="info-left">
            <div class="IDnum pd37">
              <p>身份证号:</p>
              <a-input style="width: 350px" placeholder="请输入身份证号" disabled v-model="idCard" :maxLength="18" />
            </div>
            <div class="politics pd37">
              <p>政治面貌:</p>
              <a-select placeholder="请选择政治面貌" :value="politicalStatus" style="width: 220px"
                @select="politicalStatus=$event">
                <a-select-option v-for="item in politics" :key="item">
                  {{ item }}
                </a-select-option>
              </a-select>
            </div>
            <div class="address pd37">
              <p>住所</p>
              <a-textarea placeholder="请输入住所地址" style="width: 350px" v-model="stayAddr"
                :auto-size="{ minRows: 3, maxRows: 3 }" />
            </div>
            <div class="Email pd37">
              <p>电子邮箱:</p>
              <a-input style="width: 350px" placeholder="请输入电子邮箱" v-model="email" />
            </div>
            <div class="postal-code pd37">
              <p>文化程度</p>
              <a-select placeholder="请选择文化程度" :value="eduLevel" style="width: 220px" @select="(e) => (eduLevel = e)">
                <a-select-option v-for="item in education" :key="item">
                  {{ item }}
                </a-select-option>
              </a-select>
            </div>
            <div class="small-scale pd37" v-if="idCardHand">
              <p class="small-scal-title"><span class="red">*</span> 上传身份证照片</p>
              <div class="upload-rel">
                <div class="upload">
                  <div class="upload-item">
                    <div class="upload-btn id-front" :class="{'nobackground':idFront}" @click="uploadIdCard('idFront')">
                      <img v-if="idFront" :src="idFront" alt="" class="idPic">
                      <a-spin class="spin" spinning v-if="isLoading&&uploadType ==='idFront'"/>
                      <a-icon v-else-if="!idFront" type="plus-circle" class="plus" theme="filled"/>
                    </div>
                    <div class="upload-msg">* 请上传手持身份证人像面</div>
                  </div>
                  <div class="upload-item">
                    <div class="upload-btn id-back" :class="{'nobackground':idBack}" @click="uploadIdCard('idBack')">
                      <img v-if="idBack"  :src="idBack" alt="" class="idPic">
                      <a-spin class="spin" spinning v-if="isLoading&&uploadType =='idBack'"/>
                      <a-icon v-else-if="!idBack" type="plus-circle" class="plus" theme="filled"/>
                    </div>
                    <div class="upload-msg">* 请上传手持身份证国徽面</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="Email pd37" v-if="bankImg">
              <p><span class="red">*</span> 银行账号:</p>
              <a-input style="width: 350px" placeholder="请输入电子邮箱" v-model="bankAccount" disabled/>
            </div>
            <div class="small-scale pd37" v-if="bankImg">
              <p class="small-scal-title"><span class="red">*</span> 上传银行卡照片</p>
              <div class="upload-rel">
                <div class="upload">
                  <div class="upload-item">
                    <div class="upload-btn bank-front" :class="{'nobackground':bankFront}" @click="uploadIdCard('bankFront')">
                      <img v-if="bankFront" :src="bankFront" alt="" class="idPic">
                      <a-spin class="spin" spinning v-if="isLoading&&uploadType =='bankFront'"/>
                      <a-icon v-else-if="!bankFront" type="plus-circle" class="plus" theme="filled"/>
                    </div>
                    <div class="upload-msg">* 请上传银行卡正面</div>
                  </div>
                  <div class="upload-item">
                    <div class="upload-btn bank-back" :class="{'nobackground':bankBack}" @click="uploadIdCard('bankBack')">
                      <img v-if="bankBack" :src="bankBack" alt="" class="idPic">
                      <a-spin class="spin" spinning v-if="isLoading&&uploadType =='bankBack'" />
                      <a-icon v-else-if="!bankBack" type="plus-circle" class="plus" theme="filled"/>
                    </div>
                    <div class="upload-msg">* 请上传银行卡反面</div>
                  </div>
                </div>
              </div>
            </div>
            
          </div>
          <div class="info-right">
            <div class="Phone pd37">
              <p>移动电话</p>
              <a-input v-model="bankReservedMobile" @input="bankReservedMobile=$event.target.value.replace(/[^\d]/g,'')"
                placeholder="请输入电话号码" :maxLength="11" style="width: 350px" />
            </div>
            <div class="nation pd37">
              <p>民族</p>
              <a-select placeholder="请选择民族" :value="belongNation" style="width: 180px" @select="belongNation = $event">
                <a-select-option v-for="item in nation" :key="item">
                  {{ item }}
                </a-select-option>
              </a-select>
            </div>
            <div class="postal-code pd37">
              <p>身份证有效期</p>
              <a-space>
                <!-- <a-range-picker format="YYYY-MM-DD" :value="[idCardStartDate, idCardEndDate]" @change="onIndateChange" class="w350" /> -->
                <a-date-picker v-model="idCardStartDate" placeholder="开始日期" :disabled-date="disabledStartDate" style="width: 135px">
                </a-date-picker>
                至
                <a-date-picker v-if="!long" v-model="idCardEndDate" placeholder="结束日期" :disabled-date="disabledEndDate" style="width: 135px">
                </a-date-picker>
                <a-checkbox v-model="long" @change="idCardEndDate = undefined">长期
                </a-checkbox>
              </a-space>
            </div>
            <div class="postal-code pd37 pd40">
              <p>邮政编码</p>
              <a-input placeholder="请输入邮政编码" v-model="postalCode"
                @input="postalCode=$event.target.value.replace(/[^\d]/g,'')" style="width: 350px" />
            </div>
            <div class="annotation">
            </div>
          </div>
        </div>
        <div class="info-bottom">
          <a-row type="flex" :gutter="40" class="pd37" v-if="needRealNameRegImg">
            <a-col :span="24"><p>上传工商实名注册截图（参考右图）</p></a-col>
            <a-col :span="6">
              <div class="add-gs" @click="uploadIdCard('realNameRegImg')">
                <template v-if="realNameRegImg">
                  <img :src="realNameRegImg">
                  <img class="clear" @click.stop="realNameRegImg = null" src="@/assets/images/u895.svg" alt="">
                  <!-- <div class="mark" @click.stop="showImg(realNameRegImg)">
                    <a-space>
                      <a-icon type="eye"/>
                      <a-icon type="upload" @click.stop="uploadIdCard('realNameRegImg')"/>
                    </a-space>
                  </div> -->
                </template>
                <div v-else>
                  <p><a-icon type="plus"></a-icon></p>
                  <div>工商实名注册截图</div>
                </div>
              </div>
            </a-col>
            <a-col :span="18">
              <div class="add-step">
                <a-row class="step" type="flex" style="margin-left: 0;margin-right: 0;" :gutter="60">
                  <a-col :span="8">
                    <p>步骤一：手机扫描二维码下载“登记注册身份验证APP”</p>

                    <img @click="showImg(require('@/assets/images/ICBC-step/u1216.png'))" src="@/assets/images/ICBC-step/u1216.png" alt="">
                  </a-col>
                  <a-col :span="8">
                    <p>步骤二： 点击“新用户注册”，完成注册及身份验证</p>
                    <img @click="showImg(require('@/assets/images/ICBC-step/u1218.png'))" src="@/assets/images/ICBC-step/u1218.png" alt="">

                  </a-col>
                  <a-col :span="8">
                    <p>步骤三：按下图选择选项，设置成功后将该页截图上传</p>
                    <img @click="showImg(require('@/assets/images/ICBC-step/u1217.png'))" src="@/assets/images/ICBC-step/u1217.png" alt="">

                  </a-col>
                </a-row>
              </div>
            </a-col>
          </a-row>

          <div class="small-scale pd37" style="width: 350px">
            <p class="small-scal-title">小规模纳税人登记名称</p>

            <a-row type="flex" align="middle" class="tp10">
              <a-col :span="4">{{ prefixName }}</a-col>
              <a-col :span="10">
                <a-input class="pl" v-model="firstAltName" @change="firstError=false"></a-input>
              </a-col>
              <a-col :span="9" align="center">{{ suffixName }}</a-col>
              <a-col class="error" v-if="firstError">*请填写小规模纳税人登记名称</a-col>
            </a-row>
            <p>
              备选1：&nbsp;&nbsp;
              <a-input class="pl" v-model="secondAltName" />
            </p>

            <p>
              备选2：&nbsp;&nbsp;
              <a-input class="pl" v-model="thirdAltName" />
            </p>
          </div>
        </div>
        
        <!-- 暂时弃用 -->

        <!-- <div class="signtory">
          <span>授权他人为该交易主体的合同签署人</span>
          &nbsp;&nbsp;
          <a-switch @change="$event ? (isAuthSigner = 1) : (isAuthSigner=0)" :checked="isAuthSigner ? true : false">
            <a-icon slot="checkedChildren" type="check" />
            <a-icon slot="unCheckedChildren" type="close" />
          </a-switch>
          <a-row type="flex" align="middle" class="ant-row" :class="!isAuthSigner ? 'opct' : ''">
            <a-col :span="2">签署人姓名:</a-col>
            <a-col :span="7">
              <a-input v-model="signerName" placeholder="姓名" class="notact-input"
                :class="signerName != '' ? 'activate-input' : ''" />
            </a-col>
            <a-col :span="2"></a-col>
            <a-col :span="2" align="center">身份证号: </a-col>
            <a-col :span="7">
              <a-input v-model="signerIdCard" placeholder="身份证号"
                @input="signerIdCard=$event.target.value.replace(/[^\d]/g,'')" class="notact-input" :maxLength="18"
                :class="signerIdCard != null ? 'activate-input' : ''" />
            </a-col>
            <a-col :span="5"></a-col>
          </a-row>
          <a-row type="flex" align="middle" class="ant-row" :class="!isAuthSigner ? 'opct' : ''">
            <a-col :span="2">签署手机号:</a-col>
            <a-col :span="7">
              <a-input v-model="signerMobile" placeholder="手机号"
                @input="signerMobile=$event.target.value.replace(/[^\d]/g,'')" :maxLength="11" class="notact-input"
                :class="signerMobile != null ? 'activate-input' : ''" />
            </a-col>
          </a-row>
        </div> -->
      </div>
      <div class="next">
        <a-space>
          <a-button @click="$router.back()">返回</a-button>
          <a-button type="primary" @click="next" :loading="loading">提交</a-button>
        </a-space>
      </div>
    </div>

    <a-modal :width="800" :visible="imgVisible" :footer="null" @cancel="imgVisible = false">
      <img style="width: 100%" :src="previewImage" />
    </a-modal>
    <!-- 上传img -->
    <input v-show="false" 
      id="addImage" 
      ref="addImage" 
      class="upload" 
      type="file" 
      name="addImage" 
      multiple="multiple"
      accept="image/png,image/jpeg,image/gif,image/jpg" 
      @change="getImgFile" />
  </div>
</template>
<script>
import { addapply } from "../../api/seller";
import { queryApplyDetail, editApplySave, echo } from "../../api/user";
import { selectregion, selectpurchaser } from "../../api/seller";
import { nation,uploadPhoto } from "../../api/utils";
import { politics, education } from '@/utils/constArr'
import moment from 'moment'
const regEmail = /.{1,}@.{1,}\..{1,}/
export default {
  data() {
    return {
      imgVisible: false,
      previewImage: '',
      nation: nation(),
      steps: 2,
      reg: "个体工商户注册申请",
      site: "青岛胶州",
      politics,  //政治面貌
      education, //文化程度
      eduLevel: '高中', //文化程度
      company: [], //关联公司
      purchaserId: "", //关联采购商ID
      commercialName: '',       //关联公司名字

      sw: false,
      legalName: "", // 法人姓名
      idCard: "", // 法人身份证号
      sex: null, // 性别
      belongNation: "汉族", // 民族
      supplierName: "杭州91", // 供应商名称
      bankReservedMobile: "", // 银行预留手机号
      politicalStatus: '群众', // 政治面貌
      email: "", // 电子邮件
      postalCode: "", // 邮编
      prefixName: "", // 公司注册名前缀
      suffixName: "", // 公司注册名后缀
      firstAltName: "", // 第一工商名称
      firstError: false, // 第一工商名称未填警示
      secondAltName: "", // 第二工商名称
      thirdAltName: "", // 第三工商名称
      isAuthSigner: 0, // 是否授权人（0：不授权，1:授权）
      signerName: "", // 签署人姓名
      signerIdCard: null, // 签署人身份证号
      signerMobile: null, // 签署人手机号
      regionId: "", //祱区id
      taxRegionName: "",//祱区名称
      stayAddr: "", // 住所地址
      loading: false,
      idCardEndDate: undefined,
      idCardStartDate: undefined,
      error: {
        email: "请填写正确的邮箱",
        firstAltName: "请填写第一工商名称",
        signerName: "请填输入签署人姓名",
        signerIdCard: "请填输入签署人身份证号",
        signerMobile: "请填输入签署人手机号",
      },
      isLoading:false,//上传图片
      uploadType:'',//图片上传类型
      idFront:'', //身份证正面
      idBack:'', //身份证反面
      bankFront:'', //银行卡正面
      bankBack:'', //银行卡反面
      bankAccount:'',//银行卡号
      idCardHand:false, //是否需要手持身份证
      bankImg:false,// 是时候需要银行卡照片
      needRealNameRegImg: false, // 是否需要工商截图
      realNameRegImg: null,
      supplierId: null,
      id: null,
      long: false // 身份证长期
    };
  },
  created() {
    this.getID();
    //获取区域列表 匹配当前区域
    selectregion().then(res => {
      let data = {}
      //是否有区域id
      if(this.$route.query.taxRegionId){
        data = res.data.find(item => {
          return item.id == this.$route.query.taxRegionId
        })
      }else{
        data = res.data.find(item => {
        return item.regionName == this.$route.query.regionName
      })
      }
      // const data = res.data.find(item => {
      //   return item.id == this.$route.query.taxRegionId
      // })
      console.log(data)
      data.config = JSON.parse(data.config)
      this.prefixName = data.config.registrationPrefix
      this.suffixName = data.config.registrationSuffix
      this.taxRegionName = data.regionName
      this.regionId = data.id
      this.idCardHand = data.regionConfig.idCardHand
      this.bankImg = data.regionConfig.bankImg
      this.needRealNameRegImg = data.regionConfig.needRealNameRegImg
    })
    // this.getface();
    if (this.$route.query.isedit) {
      queryApplyDetail(this.$route.query.regionId, this.$route.query.individualId).then(res => {
        console.log(2222, res)
        if (res.code === 200) {
          const data = res.data
          this.id = data.id
          this.legalName = data.legalName
          this.stayAddr = data.stayAddr
          this.postalCode = data.postalCode
          this.email = data.email
          this.firstAltName = data.firstAltName
          this.secondAltName = data.secondAltName
          this.thirdAltName = data.thirdAltName
          this.belongNation = data.belongNation || '汉族'
          this.signerName = data.signerName
          this.signerMobile = data.signerMobile
          this.signerIdCard = data.signerIdCard
          this.prefixName = data.prefixName
          this.suffixName = data.suffixName
          this.supplierId = data.supplierId
          this.politicalStatus = data.politicalStatus
          this.bankReservedMobile = data.bankReservedMobile
          this.idCard = data.idCard
          this.eduLevel = data.eduLevel || '高中'
          this.isAuthSigner = data.isAuthSigner

          this.bankAccount = data.bankAccount
          this.idFront = data.idCardHandFrontUrl
          this.idBack = data.idCardHandBackUrl
          this.bankFront = data.bankCardImgFrontUrl
          this.bankBack = data.bankCardImgBackUrl
          this.realNameRegImg = data.realNameRegisterImgUrl

          if (res.data.idCardEndDate === '长期') {
            this.long = true
          } else {
            this.idCardEndDate = res.data.idCardEndDate
          }
          this.idCardStartDate = data.idCardStartDate
        } else {
          this.$message.error(res.message)
        }
      })
    } else {
      
      echo({ individualId: this.$route.query.individualId }).then(res => {
        if (res.code === 200) {
          const data = res.data
          this.legalName = data.legalName
          this.idCard = data.idCard
          this.belongNation = data.belongNation || '汉族'
          this.bankReservedMobile = data.bankReservedMobile
          this.politicalStatus = data.politicalStatus || '群众'
          this.email = data.email
          this.postalCode = data.postalCode
          this.eduLevel = data.eduLevel || '高中'
          this.stayAddr = data.stayAddr
          this.firstAltName = data.legalName
          this.idCardStartDate = data.idCardStartDate
          this.bankAccount = data.bankAccount
          if (res.data.idCardEndDate === '长期') {
            this.long = true
          } else {
            this.idCardEndDate = res.data.idCardEndDate
          }
        }
      })
    }
  },
  methods: {
    //点击上传按钮
    uploadIdCard(type) {
      this.uploadType = type
      this.$refs.addImage.dispatchEvent(new MouseEvent('click'))
    },
    getImgFile(e) {
      if (this.uploadType !== 'realNameRegImg') {
        if (e.target.files[0].type.indexOf('image') === -1) {
          this.$message.error('请上传正确的格式')
          e.target.value = null
          return
        }
        if ((e.target.files[0].size / 1024 / 1024) > 3) {
          this.$message.error('图片大小超过3MB，请进行图片压缩处理再上传识别')
          e.target.value = null
          return
        }
      }
      this.isLoading = true
      uploadPhoto(Array.from(e.target.files)[0], (url) => {
        this.isLoading = false
        // this.form[this.uploadLabel] = url
        this[this.uploadType] = url
        this.uploadType = ''
      })
      e.target.value = null
    },
    // s祱区id获取
    onSearch(value) {
      if (value) {
        selectpurchaser({ commercialName: value }).then((res) => {
          this.company = res.data;
        });
      } else {
        this.$message.error("请填写关联公司");
      }
    },
    getID() {
      // this.sex = this.$route.query.sex;
      // this.bankReservedMobile = this.$route.query.mobile;
      // this.idCard = this.$route.query.idCard
      // this.legalName = this.$route.query.legalName
    },
    swt(e) {
      this.sw = e;
      if (e == true) {
        this.isAuthSigner = "1";
      } else {
        this.isAuthSigner = "0";
      }
    },
    getSelID(d) {
      this.commercialName = d.commercialName
      this.purchaserId = d.id;
    },
    next() {
      let verify = false;
      if (this.idCardStartDate && !this.long && !this.idCardEndDate) {
        this.$message.error('请填写正确的身份证有效期')
      } else if (this.email && !regEmail.test(this.email)) {
        this.$message.error(this.error.email);
      } else if (!this.firstAltName) {
        this.$message.error(this.error.firstAltName);
        this.firstError = true
      } else if (this.isAuthSigner == "1" && !this.signerName) {
        this.$message.error(this.error.signerName);
      } else if (this.isAuthSigner == "1" && !this.signerIdCard) {
        this.$message.error(this.error.signerIdCard);
      } else if (this.isAuthSigner == '1' && (this.signerIdCard == this.idCard)) {
        this.$message.error('纳税人身份证与签属人身份证不能相同')
      } else if (this.isAuthSigner == "1" && !this.signerMobile) {
        this.$message.error(this.error.signerMobile);
      } else if (this.needRealNameRegImg && !this.realNameRegImg) {
        this.$message.error('请上传工商实名注册截图')
      } else if (this.idCardHand||this.bankImg) {
        if(!this.idFront){
          this.$message.error('请添加手持身份证正面照片');
          return
        }else if(!this.idBack){
          this.$message.error('请添加手持身份证反面面照片');
          return
        }
        if(!this.bankFront){
          this.$message.error('请添加银行卡正面照片');
          return
        }else if(!this.bankBack){
          this.$message.error('请添加银行卡反面面照片');
          return
        }
        verify = true;
      } else {
        verify = true;
      }
      if (verify) {
        this.loading = true
        if (this.$route.query.isedit) {
          const config = {
            legalName: this.legalName,
            idCard: this.idCard,
            bankReservedMobile: this.bankReservedMobile,
            supplierName: this.prefixName + this.firstAltName + this.suffixName,
            supplierId: this.supplierId,
            individualApplyId: this.id,
            belongNation: this.belongNation,
            politicalStatus: this.politicalStatus,
            stayAddr: this.stayAddr,
            postalCode: this.postalCode,
            email: this.email,
            eduLevel: this.eduLevel,
            firstAltName: this.firstAltName,
            secondAltName: this.secondAltName,
            thirdAltName: this.thirdAltName,
            isAuthSigner: this.isAuthSigner,
            signerName: this.isAuthSigner ? this.signerName : undefined,
            signerIdCard: this.isAuthSigner ? this.signerIdCard : undefined,
            signerMobile: this.isAuthSigner ? this.signerMobile : undefined,
            idCardEndDate: this.long && this.idCardStartDate ? '长期' : (this.idCardEndDate ? moment(this.idCardEndDate).format('YYYY-MM-DD') : undefined),
            idCardStartDate: this.idCardStartDate ? moment(this.idCardStartDate).format('YYYY-MM-DD') : undefined,
            realNameRegisterImgUrl: this.realNameRegImg || null,
          };
          if(this.idCardHand){
            config.idCardHandFrontUrl = this.idFront
            config.idCardHandBackUrl = this.idBack
          }
          if(this.bankImg){
            config.bankCardImgFrontUrl = this.bankFront
            config.bankCardImgBackUrl = this.bankBack
          }
          console.log(config);
          // 编辑
          editApplySave(config).then(res => {
            this.loading = false
            if (res.code === 200) {
              this.$message.success(res.message)
              this.$router.back()
            } else {
              this.$message.error(res.message);
            }
          }).catch(() => { this.loading = false })
        } else {
          const config = {
            legalName: this.legalName,
            idCard: this.idCard,
            sex: this.sex,
            belongNation: this.belongNation,
            supplierName: this.prefixName + this.firstAltName + this.suffixName,
            bankReservedMobile: this.bankReservedMobile,
            politicalStatus: this.politicalStatus,
            email: this.email,
            regionId: this.regionId,
            taxRegionName: this.taxRegionName,
            postalCode: this.postalCode,
            prefixName: this.prefixName,
            suffixName: this.suffixName,
            firstAltName: this.firstAltName,
            eduLevel: this.eduLevel,
            secondAltName: this.secondAltName,
            thirdAltName: this.thirdAltName,
            isAuthSigner: this.isAuthSigner,
            signerName: this.signerName,
            signerIdCard: this.signerIdCard,
            signerMobile: this.signerMobile,
            stayAddr: this.stayAddr,
            idCardEndDate: this.long && this.idCardStartDate ? '长期' : (this.idCardEndDate ? moment(this.idCardEndDate).format('YYYY-MM-DD') : undefined),
            idCardStartDate: this.idCardStartDate ? moment(this.idCardStartDate).format('YYYY-MM-DD') : undefined,
            realNameRegisterImgUrl: this.realNameRegImg || null,
          };
          if(this.idCardHand){
            config.idCardHandFrontUrl = this.idFront
            config.idCardHandBackUrl = this.idBack
          }
          if(this.bankImg){
            config.bankCardImgFrontUrl = this.bankFront
            config.bankCardImgBackUrl = this.bankBack
          }
          // 添加
          addapply(config).then((res) => {
            this.loading = false
            if (res.code == 200) {
              this.$message.success(res.message);
              this.$router.back()
            } else {
              this.$message.error(res.message);
            }
          }).catch(() => { this.loading = false })
        }
      }
    },
    getval(e) {
      this.politicalStatus = e;
    },
    onIndateChange (m, e) {
      this.idCardStartDate = e[0]
      this.idCardEndDate = e[1]
    },
    disabledStartDate (current) {
      return current && this.idCardEndDate && current > moment(this.idCardEndDate)
    },
    disabledEndDate (current) {
        return current && this.idCardStartDate && current < moment(this.idCardStartDate)
    },
    showImg (url) {
      this.previewImage = url
      this.imgVisible = true
    }
  },
};
</script>
<style lang="less" scoped>
.applyshop {
  margin-bottom: 100px;
  .mainbody {
    min-height: 1064px;
    background-color: #ffffff;
    margin: 20px auto;
    position: relative;
    .mainbody-title {
      :nth-child(1) {
        font-size: 20px;
      }
      color: rgba(0, 0, 0, 0.847058823529412);
      font-size: 16px;
      font-weight: 700;
    }
    .personal-info {
      .info-name {
        padding: 50px 0 0 30px;
        font-size: 20px;
        font-weight: 700;
        color: rgba(0, 0, 0, 0.847058823529412);
      }
      .info {
        display: flex;
        padding: 0 0 0 30px;
        font-size: 14px;
        font-weight: 400;
        .info-left {
          .IDnum {
            padding: 20px 0 35px 0;
          }
        }
        .info-right {
          padding: 0 0 0 85px;
          .Phone {
            padding: 20px 0 35px 0;
          }
          .annotation {
            padding: 0 0 0 20px;
            p {
              padding: 20px 0 0 0;
              line-height: 20px;
              width: 375px;
            }
          }
        }
        .pd40 {
          padding-top: 40px;
        }
      }
      .signtory {
        padding: 0 0 0 30px;
        font-size: 15px;
        font-weight: 700;
        color: rgba(0, 0, 0, 0.847058823529412);
        .ant-row {
          font-size: 14px;
          font-weight: 400;
          padding: 15px 0;
          .notact-input {
            background-color: rgba(245, 245, 245, 1);
          }
          .activate-input {
            background-color: #ffffff;
          }
        }
        .opct {
          opacity: 0;
        }
      }
    }
    .tp10 {
      padding: 10px 0 0 0;
      position: relative;
      .error {
        width: 100%;
        position: absolute;
        right: -100%;
        color: red;
      }
    }
    .w54 {
      display: inline-block;
    }
    .next {
      width: 137px;
      height: 40px;
      cursor: pointer;
      font-size: 16px;
      font-weight: 400;
      border-radius: 4px;
      line-height: 40px;
      text-align: center;
      margin: 50px auto;
      color: #ffffff;
    }
  }
}
.red{
  color: red;
}
.pd37 {
  padding: 0 0 30px 0;
  line-height: 30px;
}
.small-scale {
  .pl {
    width: 145px;
    padding: 0 0 0 10px !important;
  }
  p {
    padding: 10px 0 0 0;
  }
  :nth-child(1) {
    padding: 0;
  }
  .small-scal-title {
    font-size: 14px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.847058823529412);
  }
  .upload-rel{
    position: relative;
    height: 250px;
  }
  .upload{
    display: flex;
    position: absolute;
    .upload-item{
      margin-right: 20px;
      .upload-msg{
        color: red;
      }
      .upload-btn{
        width: 350px;
        height: 214px;
        background: inherit;
        background-color: rgba(255, 255, 255, 1);
        box-sizing: border-box;
        border-width: 1px;
        border-style: solid;
        border-color: #e5e5e5;
        border-radius: 20px;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        text-align: center;
        background-repeat: no-repeat;
        .spin{
          margin-top: 100px;
        }
        &.id-front{
          background-image: url(../../assets/images/front.png);
          background-size: cover;
        }
        &.id-back{
          background-image: url(../../assets/images/back.png);
          background-size: cover;
        }
        &.bank-front{
          background-image: url(../../assets/images/bank-front.png);
          background-size: cover;
        }
        &.bank-back{
          background-image: url(../../assets/images/bank-back.png);
          background-size: cover;
        }
        &.nobackground{
          background-image: none;
        }
        .idPic{
          // width:100% ;
          height: 100%;
          object-fit: cover;
        }
        .plus{
          position: absolute;
          color: rgba(0, 97, 255, 1);
          left: 50%;
          top: 50%;
          z-index: 2;
          font-size: 60px;
          margin-left: -30px;
          margin-top: -30px;
        }
      }
    }
  }
}

.add-gs {
  height: 360px;
  background-color: rgba(242, 242, 242, 1);
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(215, 215, 215, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  position: relative;
  img {
    width: 100%;
  }
  i {
    font-size: 27px;
  }
  .clear {
    width: 30px;
    position: absolute;
    right: -15px;
    top: -15px;
  }
  .mark {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    transition: all .3s;
    opacity: 0;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, .4);
    font-size: 18px;
    cursor: pointer;
  }
  &:hover {
    .mark {
      opacity: 1;
    }
  }
}
.add-step {
  height: 360px;
  background-color: rgba(242, 242, 242, 1);
  .step {
    width: 100%;
    padding: 20px 20px 0 20px;
    p {
      font-family: '微软雅黑', sans-serif;
      font-weight: 400;
      color: #555555;
      line-height: 24px;
      margin-bottom: 24px;
    }
    img {
      cursor: pointer;
      width: 191px;
      @media screen and (max-width: 1344px){
        width: 145px;
      }
    }
  }
}
.info-bottom {
      padding: 0 0 0 30px;
}
</style>